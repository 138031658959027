.product-table-container {
  display: flex;
  flex-direction: column;
  height: 92%;
}
.product-table-content {
  min-height: 550px;
  /* overflow-y: auto; */
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  align-items: center;
}
.loadingsvg-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.loadingsvg-Image img {
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
