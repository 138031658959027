
.box {
    width: calc(33.333% - 20px); 
    margin: 10px;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .box:hover {
    transform: translateY(-10px); 
  }
  
  .box-content {
    padding: 20px;
    text-align: center;
  }
  
  .box-title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
  }
  
  .box-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .box:hover .box-overlay {
    opacity: 1; 
  }
  
  .box-icon {
    color: #fff;
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .box-icon:hover {
    color: #70bcf7; 
  }
  