.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 6.5px;
  text-align: center;
  align-content: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f8f9fa;
  color: #343a40;
}

table .fa-eye,
table .fa-file-circle-plus {
  cursor: pointer;
  color: #007bff;
  transition: color 0.3s;
}

table .fa-eye:hover,
table .fa-file-circle-plus:hover {
  color: #0056b3;
}

.section-left,
.section-right {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.section-right {
  margin-left: 20px;
}

h4 {
  margin-bottom: 20px;
  color: #495057;
}

@media (max-width: 768px) {
  .section-left,
  .section-right {
    margin: 10px 0;
  }
  .table {
    font-size: 14px;
  }
  .table th,
  .table td {
    padding: 6px;
  }
}
