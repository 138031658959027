.admin-login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.admin-login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding: 20px;
  min-width: 400px;
}

.Paasword {
  border-radius: 0px !important;
}

.admin-logo {
  margin-left: 20px !important;
}

.admin-login-slider {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slider-container {
  position: absolute;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 200px;
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.slide.active {
  display: block;
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dots {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.dot.active {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
  .admin-login-container {
    flex-direction: column;
  }

  .admin-login-form,
  .admin-login-slider {
    flex: none;
    width: 100%;
    height: 50%;
  }
}

.admin-login-slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: rgb(43, 40, 40);
  text-align: center;
  padding: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.slider-text {
  margin-top: 800px;
}

.slider-text h2 {
  color: #454546;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-text p {
  color: rgb(163, 159, 159);
  font-size: 13px;
  margin-top: 10px;
}

.text-muted {
  font-size: 13px;
}

p.text-end .text-decoration-none {
  text-decoration: none;
}

.googlebutton {
  border: 1px solid rgb(173, 171, 171) !important;
}

.admin-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: #333;
}

.login-container {
  width: 100%;
  max-width: 450px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-logo {
  width: 100px;
  margin-bottom: 35px;
}

.description-text {
  font-size: 0.7rem;
  color: #666;
  text-align: center;
  margin-bottom: 60px;
}

.form-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 25px !important;
  text-align: center;
  color: #717171;
}

.error-message {
  color: red;
  font-size: 0.85rem;
  margin-bottom: 10px;
  text-align: center;
}

.custom-input {
  width: 100%;
  margin-bottom: 20px !important;
}

.login-button {
  width: 100%;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  text-transform: none;
  margin-bottom: 30px !important;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Button Hover Effect */
.login-button:hover {
  background-color: #0056b3;
}

@keyframes glowing-wave {
  0% {
    box-shadow: 0 0 5px #007bff, 0 0 10px #007bff, 0 0 15px #007bff;
  }
  50% {
    box-shadow: 0 0 20px #0056b3, 0 0 30px #0056b3, 0 0 40px #0056b3;
  }
  100% {
    box-shadow: 0 0 5px #007bff, 0 0 10px #007bff, 0 0 15px #007bff;
  }
}
.button-wrapper {
  position: relative;
}

.login-button:disabled {
  background-color: #0056b3;
  pointer-events: none;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer-text {
  font-size: 0.75rem;
  color: #999;
  text-align: center;
  margin-top: 20px;
}

.welcome-text {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  color: #007bff; /* Fallback color */
  background: linear-gradient(90deg, #007bff, #00bfff, #007bff);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: waveGlow 2s linear infinite;
}

@keyframes waveGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
