
.field-containers {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
  }
  
  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 10px;
  }
  
  
  .image-item {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }
  

  .selected-image,
  .image-item video {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
 
  .image-item:hover .selected-image,
  .image-item:hover video {
    transform: scale(1.05);
  }

  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    border: none;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s ease;
  }
 
  .delete-button:hover {
    background-color: red;
  }

  .btn-primary {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn-primary:hover {
    background-color: #0056b3;
  }

  .video-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  