.container-fluid {
    padding: 20px;
}

.input-field-container {
    margin-bottom: 15px;
}

.input-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.input-field:focus {
    border-color: #3f51b5;
    outline: none;
}

.readonly {
    background-color: #f5f5f5;
    cursor: not-allowed;
    color: #666;
}


.textarea {
    height: 100px;
    resize: none;
    overflow-y: auto;
}

.status-label {
    margin-top: 10px;
    font-weight: bold;
}