.navbar,
.footer {
  width: 100%;
}

.navbar_main {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding: 10px 20px; */
  margin: 0px 20px;
}

.footer {
  display: flex;
}

.footer_main {
  left: 280px;
  bottom: 0;
  background: white;
  text-align: center;
  padding: 15px 20px;
  position: fixed;
  height: 50px;
  right: 20px;
}

.navbar_main i {
  font-size: 20px;
  cursor: pointer;
}