.product-variant-edit-form {
    border: none;
    width: 100%;
    background-color: none;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 20px;
}

.form-title {
    font-size: 1.5rem;
    margin: 0;
}

.top-bar-buttons button {
    margin-left: 15px;
}

.variant-list-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 5px;
}

.list-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #e9ecef;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.col-header {
    flex: 1;
    text-align: center;
}

.variant-list .variant-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.variant-item .col-md-4,
.variant-item .col-md-2 {
    flex: 1;
    text-align: center;
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}