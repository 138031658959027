.upload-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 2px dashed #007bff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  min-height: 150px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-container:hover {
  background-color: #e9ecef;
}

.drag-drop-message {
  color: #6c757d;
  font-size: 16px;
  text-align: center;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.image-preview-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 5px;
}

.file-name {
  font-size: 14px;
  color: #495057;
  margin-bottom: 5px;
  text-align: center;
}

.delete-button {
  background-color: transparent;
  border: none;
  color: #e60000;
  cursor: pointer;
  transition: color 0.3s;
}

.delete-button:hover {
  color: #ff0000;
}

.upload-disabled-message {
  color: #dc3545;
  margin-top: 10px;
}

.message-box {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.message-box.success {
  background-color: #d4edda;
  color: #155724;
}

.message-box.error {
  background-color: #f8d7da;
  color: #721c24;
}
