.sidebar {
  position: relative;
}

.sidebar_main {
  background: #1467a5 !important;
  padding: 10px;
  height: 100vh;
  width: 250px;
  overflow-y: auto;
  color: white;
  position: fixed;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf #1467a5 !important;
  z-index: 1;
}

.sidebar_main::-webkit-scrollbar {
  display: none;
}

.sidebar_heading {
  padding: 15px 10px 15px 20px;
  align-items: center;
}

.sidebar_subheading {
  background: transparent !important;

  padding: 10px 10px 10px 20px;
  align-items: center;
}

.sidebar_subheading h6 {
  font-size: 10px !important;
  color: rgb(187, 186, 186);
}

.sidebar_dropdown {
  padding: 6px 20px 10px 20px;
}

.max-h-40 {
  max-height: 10rem /* 160px */;
}

.max-h-100 {
  max-height: 30rem /* 160px */;
}

.max-h-0 {
  max-height: 0px;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.overflow-hidden {
  overflow: hidden;
}

.bi-chevron-up,
.bi-chevron-down {
  font-weight: 900;
}

.sidebar_dropdown_list ul {
  padding-left: 10px;
}

.sidebar_dropdown_list ul li a {
  color: #fff;
  padding: 8px 0px 8px 10px;
}

.sidebar_dropdown_list ul li:hover a {
  color: rgb(214, 214, 107);
  font-weight: bold !important;
}

.sidebar_dropdown_list ul li .active:hover {
  color: #1467a5 !important;
}

.sidebar_dropdown_list ul li .active {
  color: #1467a5;
  font-weight: 600;
  width: 100%;
  background: rgb(214, 214, 107);
}

.sidebar_dropdown_list ul li:hover {
  font-weight: 600;
  width: 100%;
  background: rgb(255, 255, 255, 0.1);
}

.subListSidebar {
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
}

.sidebar_sublist {
  padding-left: 5px;
}

.sidebar_sublist:hover {
  background-color: rgba(216, 178, 55, 0.39) !important;
  padding: -10px;
}

.sidebar_sublist:hob .sidebar_dropdown ul li {
  font-size: 14px;
}

.custom-span {
  color: white;
  transition: color 0.3s ease;
}

.activeSiderbarPage {
  color: grey !important;
}

.pointer {
  cursor: pointer;
}
