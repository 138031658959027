.product_form_main {
  margin: 20px;
  padding: 20px;
  display: flex;
}

.layout_comp {
  margin: 0px 20px 60px 20px;
  padding: 15px;
  background: #fff !important;
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.layout_comp_no_bg {
  margin: 0px 20px 60px 20px;
  height: 80vh;
  overflow: auto;
  scrollbar-width: thin;
}

.px-20px {
  padding: 0px 20px;
}

.product_form_main_left div[role="button"] {
  width: 200px;
}

.acriveFromTab {
  border-bottom: 3px solid rgb(215, 218, 218) !important;
  background: rgb(238, 239, 239);
  transition: 0.2s;
}

.product_form_tab {
  height: 50px;
  border-bottom: 3px solid transparent;
  padding-left: 20px !important;
  transition: 0.2s;
}

.product_form_main_right {
  background: rgb(238, 239, 239) !important;
  width: 100%;
  padding: 15px;
  height: 70vh;
  overflow: auto;
  scrollbar-width: thin;
}

.animated_input {
  font-size: 18px;
  padding: 10px 10px 10px 10px;
  border: none;
  border: 1px solid rgb(226, 228, 228);
  width: 100%;
}

.animated_input:focus {
  outline: none;
}

/* LABEL =================== */
.animated_input_label {
  color: #999;
  background: white;
  border-radius: 3px;
  padding: 0px 5px;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.animated_input:focus ~ label,
.animated_input:valid ~ label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  color: #5264ae;
}

/* active state */
.animated_input:focus ~ .bar:before,
.animated_input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================== */
.highlight {
  position: absolute;
  top: 25%;
  left: 0;
  height: 60%;
  width: 100px;
  pointer-events: none;
  opacity: 0.5;
}

.product_form_main_right_t {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.product_form_main_right_t .group {
  width: 100%;
}

.product_form_main_right_second {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.product_form_main_right_second i {
  cursor: pointer;
  font-weight: bold;
}

.product_form_main_right_second span {
  font-weight: bold;
}

.product_form_main_right_second .product_form_description {
  width: 100%;
}

.product_form_main_right_second select {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
}

.product_form_main_right_second .border-r {
  border-right: 1px solid lightgray;
}

.description_textarea {
  background: #fff;
  height: 200px;
  overflow-y: auto;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.description_textarea textarea {
  width: 100%;
  border: none;
  height: 180px;
  padding: 10px;
}

.product_form_main_right_third {
  margin-top: 20px;
  width: 100%;
}

.product_form_main_right_third div select {
  padding: 5px;
  outline: none;
}

.thumbnil {
  margin-top: 20px;
  gap: 30px;
  display: flex;
}

.thumbnil_img div {
  gap: 50px;
}

.thumbnil_img div button {
  height: 40px;
}

.select-image-btn {
  margin-top: 20px;
  width: 100%;
}

.thumbnil_img img {
  height: 100px;
}

/* From Uiverse.io by gharsh11032000 */
/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #1467a5;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.3em;
  bottom: 0.3em;
  background-color: rgb(226, 228, 228);
  border-radius: 50px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.switch input:checked + .slider {
  background: rgb(214, 214, 107);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #b84fce;
}

.switch input:checked + .slider:before {
  transform: translateX(1.6em);
  width: 2em;
  height: 2em;
  bottom: 0;
  background: rgb(249, 250, 250);
}

.product_media_main h4 {
  border-bottom: 1px solid lightgray;
}

.product_media_img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  border: 2px dotted rgb(215, 218, 218);
  /* height: 300px; */
  overflow-y: auto;
  scrollbar-width: thin;
}

.product_media_img img {
  width: 100%;
  object-fit: contain;
}

.media_img_preview img {
  height: 200px;
}

.w-max {
  width: max-content;
}

.h-max {
  height: max-content;
}

.edit_img_preview img {
  height: 100px;
}
