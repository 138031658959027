.product_main {
  margin: 20px;
  padding: 0px 0px 50px 0px;
}

.product_main_top,
.product_main_second {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product_search {
  background: rgb(238, 239, 239) !important;
  width: 300px;
}

.product_main_top h6 {
  color: gray;
}

.product_main_top div span {
  font-size: 14px;
  font-weight: 500;
}

.product_search {
  padding: 10px 10px;
}

.product_search input {
  border: none;
  outline: none;
  background: none;
  padding: 0px 10px;
}

.product_main_third {
  /* border: 2px dotted lightgray; */
  /* background: rgb(238, 239, 239) !important; */
}

.product_main_third_table {
  overflow: auto;
  scrollbar-width: thin;
  max-height: 65vh;
}

.pagiation_product_div {
  position: absolute;
  bottom: 0;
  width: 98%;
}

.product_main_third table {
  width: 100%;
}

.table > :not(caption) > * > * {
  background: transparent;
}

.product_main_third table thead {
  background: rgb(238, 239, 239) !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.product_main_third table tbody {
  width: 100%;
}

.product_main_third table td img {
  object-fit: contain;
  height: 42px;
}

.pagination-button {
  height: 30px;
  width: 30px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_category_fields {
  display: grid;
  grid-template-columns: 20% 80%;
  margin-top: 20px;
}

.create_category_fields:nth-child(1) {
  margin-top: 0;
}

.bootstrap_logo_sm {
  height: 100px;
}

.thumbnil_img button {
  width: max-content;
}

.font-large {
  font-size: 48px;
}

.media_library_main img {
  width: 100%;
  object-fit: contain;
}

.media_library_main {
  display: grid;
  grid-template-columns: repeat(10, 8%);
  place-content: start;
  gap: 30px;
  min-height: 65vh;
  max-height: 65vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.active_status {
  background: rgb(190, 236, 190);
  width: max-content;
  font-size: 14px;
}

.variant_input {
  height: 38px;
}

.product_variants {
  max-height: 70vh;
  height: 70vh;
  overflow-y: auto;
}

.product_name_td {
  max-width: 500px;
}

.inputTrashIcon {
  padding-left: 20px;
}

.inner_page {
  color: #7a8585;
}

/* .table_product_name {
  min-width: 300px !important;
} */

.product_main_second_section {
  background: #fff;
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
