.container {
  margin: auto;
  padding: 20px;
}

/* Table container */
.table-container {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.table-classfiction {
  width: 100%;
  height: 400px;
  overflow: auto;
  border-collapse: collapse;
}

.table-classfiction th,
.table-classfiction td {
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
}

.table-classfiction th {
  background-color: #f8f9fc;
  font-weight: bold;
  text-transform: uppercase;
  color: #495057;
  font-size: 14px;
}

.table-classfiction tr:hover {
  background-color: #7fb9e267;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.table-classfiction td:hover {
  font-size: 14px;
  color: #000000 !important;
}

.table-classfiction td {
  font-size: 14px;
  color: #212529;
}

.table-classfiction input[type="checkbox"],
.table-classfiction input[type="radio"] {
  cursor: pointer;
  accent-color: #007bff;
}

/* Product table styles */
.product-table img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Search bar and counter */
.search-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.search-barr {
  display: flex;
  align-items: center;
  border: 1px solid #3975b1;
  border-radius: 10px;
  padding: 8px 12px;
  background: #ffffff;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-counter .search-barr input {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  font-size: 14px;
}

.search-counter .search-barr svg {
  margin-right: 10px;
  color: #6c757d;
}

.search-counter .product-count {
  font-size: 14px;
  color: #6c757d;
  font-weight: bold;
  text-transform: uppercase;
}

/* Action buttons */
.button-container {
  text-align: center;
  margin-top: 20px;
}

.button-container button {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.button-container button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
}

.button-container button:hover:enabled {
  background: linear-gradient(to right, #0056b3, #004080);
  transform: translateY(-2px);
}

.product-table img {
  width: 60px; /* Fixed size for all images */
  height: 60px;
  object-fit: cover; /* Ensures proper aspect ratio */
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-table .product-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-scrollable-wrapper {
  margin-top: 10px;
  max-height: 600px;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table-scrollable-wrapper::-webkit-scrollbar {
  width: 8px;
}

.table-scrollable-wrapper::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}

.table-scrollable-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3;
}

.table-scrollable-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.table-scrollable-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #47535f75 #f1f1f1;
}
