
.custom-pagination {
  display: flex;
  justify-content: end;
  align-items: end;
  margin: 20px 0;
}

.custom-pagination__button,
.custom-pagination__ellipsis {
  border: none;
  background: none;
  font-size: 16px;
  padding: 5px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  color: #333;
}

.custom-pagination__button:hover,
.custom-pagination__ellipsis:hover {
  background-color: #f0f0f0;
}

.custom-pagination__button--active {
  background-color: #007bff;
  color: #fff;
}

.custom-pagination__prev,
.custom-pagination__next {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  color: #007bff;
}

.custom-pagination__prev:hover,
.custom-pagination__next:hover {
  background-color: #007bff;
  color: #fff;
}

.custom-pagination__prev[disabled],
.custom-pagination__next[disabled] {
  color: #ccc;
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.custom-pagination__ellipsis {
  padding: 5px 15px;
  color: #999;
}

@media (max-width: 600px) {
  .custom-pagination {
    flex-wrap: wrap;
  }

  .custom-pagination__button,
  .custom-pagination__ellipsis,
  .custom-pagination__prev,
  .custom-pagination__next {
    margin: 5px;
    padding: 8px 12px;
  }
}
