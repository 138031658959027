.custom-sidebar {
  background-color: transparent;
}
.common_input {
  margin-bottom: 10px;
}
.card {
  border-radius: 8px;
  padding: 20px;
}
.row {
  margin-bottom: 15px;
}

.sidebar-card .card {
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.sidebar-card .card:hover {
  transform: translateY(-5px);
  border-color: #007bff;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}

.sidebar-card.active-card .card {
  background-color: #f0f8ff;
  color: #007bff;
}

.sidebar-card h6 {
  font-size: 0.7rem;
  margin-top: 0.5rem;
  color: #343a40;
  text-transform: uppercase;
}

.custom-sidebar .nav .card {
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-card.active-card h6 {
  font-weight: bold;
  color: #007bff;
}

.custom-sidebar .nav .card h6 {
  font-size: 0.9rem;
}

.custom-sidebar .nav .card:hover {
  background-color: transparent !important;
  transform: scale(1.02);
}

.custom-sidebar .active-card {
  color: #ffffff;
  transform: scale(1.03);
}

.main-content {
  margin-left: "57px";
  background-color: #f1f1f1;
}

.main-content .card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.main-content .card:hover {
  transform: translateY(-5px);
}

.main-content .card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.main-content .card:hover {
  transform: translateY(-5px);
}

.main-content h4 {
  font-size: 1.5rem;
}

.main-content button {
  margin-top: 1rem;
}
