@media screen and (min-width: 1023px) and (max-width: 1450px) {

  .product_form_main_right_second,
  .product_form_main_right_third,
  .product_form_main_right_t {
    flex-direction: column;
  }
}

@media screen and (min-width: 1451px) {

  .product_form_main_right_second,
  .product_form_main_right_third,
  .product_form_main_right_t {
    flex-direction: row;
  }
}