.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1300;
}

.modal-container {
  background-color: #fff;
  width: 100%;
  max-width: 845px;
  min-height: 650px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: none;
  flex-grow: 1;
  text-align: center;
}

.tab.active {
  background-color: #007bff;
  color: white;
}

.modal-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tab-content {
  margin: 10px 0;
}

.image-grid {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  max-height: 450px;
  overflow: auto;
}

.image-item {
  position: relative;

  margin: 10px;
  text-align: center;
}

.image-thumbnail {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkbox-label {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label input {
  margin: 0;
  cursor: pointer;
}

.scrollable-content {
  max-height: 450px;
  overflow-y: auto;
}

.scrollable-content p {
  margin: 0;
}

.Media-thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.copy-button {
  position: absolute;
  top: -1px;
  right: 10px;
  font-size: 20px;
  border: none;
  color: rgb(0, 0, 0);
  background-color: none;
  background: none;
  border-radius: 20px;
  padding: 5px;
  display: none;
  cursor: pointer;
  height: 30px;
}

.image-item:hover .copy-button {
  display: block;
}
