.btn-custom {
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 5px;
}

.btn-edit {
  background-color: #f0f0f0;
  color: #333;
  text-transform: uppercase;
  border: 1px solid rgb(63, 60, 60);
}
.btn-save {
  background-color: green;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.btn-save:hover {
  background-color: darkgreen;
  color: white;
}
.btn-cancel {
  color: rgb(3, 3, 3);
  font-size: 15px;
  border: 1px solid #007bff;
  background-color: transparent;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.btn-cancel:hover {
  color: rgb(15, 15, 15);
  border-color: #007bff;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.cls {
  font-size: 15px;
  padding: 8px 10px;
  border: none;
  display: inline-flex;
  border-radius: 10px;
  color: #ffffff;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  text-transform: capitalize;
  font-weight: bold;
  outline: none;
}

.btn--video {
  background-color: #007bff;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  border: 2px solid transparent;
}

.btn--video:hover {
  background-color: #007bff;
  transform: translateY(-4px);
}

.btn--video:active {
  background-color:#007bff;
  transform: translateY(2px);
}

.btn--video:focus {
  border: 2px solid #007bff;
}

.btn--image {
  background-color: #007bff;
  box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
  border: 2px solid transparent;
}

.btn--image:hover {
  background-color: #007bff;
  transform: translateY(-4px);
}

.btn--image:active {
  background-color: #007bff;
  transform: translateY(2px);
}

.btn--image:focus {
  border: 2px solid #007bff;
}
.btn__icon {
  font-size: 20px;
  transition: transform 0.2s ease;
}

.btn:hover .btn__icon {
  transform: scale(1.1);
}

