.modal-header-section {
  display: flex;
  justify-content: space-between;
  background-color: #007bff;
  color: white;
  padding: 10px;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.toggle-switch input:checked + .toggle-slider {
  background-color: #2196f3;
}
.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.media-item {
  position: relative;
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.media-item img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.media-item:hover img {
  transform: scale(1.05);
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 0.9);
}

.media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.media-item {
  margin: 10px;
}

.form-control {
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
